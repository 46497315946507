/** - - - - - - - - - - - -
 * btn
 - - - - - - - - - - - - */
.btn {
  position: relative;
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
  padding: 18px 45px;
  font-size: 16px;
  border-radius: 4px;
  .is-en & {
    text-transform: none;
  }
  .material-icons,
  .fas {
    position: absolute;
    top: 50%;
    right: 16px;
    font-size: 19px;
    transform: translateY(-50%);
    &.fa-exclamation-circle {
      position: static;
      margin-left: 7px;
      color: $primary-color;
      transform: translateY(0);
    }
  }
  box-shadow: none;
}

.btn-login {
  .side & {
    width: 100%;
    height: 54px;
    line-height: 51px;
  }
  .fas {
    font-size: 14px;
  }
  &:hover {
    color: #fff !important;
    background-color: $primary-color !important;
    box-shadow: $shadow_1;
  }
}
.btn-signup,
.btn-mypage,
.btn-profile,
.btn-registration,
.btn-search,
.btn-fcp {
  .side & {
    width: 100%;
    height: 54px;
    line-height: 51px;
  }
  color: #000;
  background-color: transparent;
  border: 2px solid $color-border-gray;
  
  .fas {
    font-size: 12px;
  }
  &:hover {
    color: #fff;
    background-color: #000;
    border-color: #000;
    box-shadow: $shadow_1;
  }
}

.btn-profile {
  background-color: #ff0;
  &:hover {
    color: #000;
    background-color: #ff0;
    border-color: $color-border-gray;
    box-shadow: $shadow_1;
    .fas {
      color: #000;
    }
  }
}

.btn-mypage {
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
  .fas {
    color: #fff;
  }
  &:hover {
    color: #fff;
    background-color: $primary-color;
    border-color: $primary-color;
    box-shadow: $shadow_1;
  }
  .info-icon {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 14px;
    height: 14px;
    background-color: #ff0;
    border-radius: 50%;
  }
}

.btn-search {
  .fas {
    font-size: 14px;
  }
}

.btn-grad {
  color: #fff;
  font-size: 14px;
  max-width: 200px;
  padding: 0.42rem 2.14rem;
  border-radius: 9999px;
  .gicon {
    font-size: 20px;
  }
  &:hover {
    color: #fff !important;
  }
  &.gray {
    background: linear-gradient(0deg, #545D5C 0%, #768180 100%);
  }
  &.silver {
    background: linear-gradient(0deg, #545D5C 0%, #768180 100%);
  }
  &.lightblue {
    background: linear-gradient(0deg, #007DB4 0%, #00A8F2 100%);
    &:hover {
      background: linear-gradient(0deg, #001DBA 0%, #0027FF 100%);
    }
  }
  &.darkblue {
    background: linear-gradient(0deg, #001DBA 0%, #0027FF 100%);
    &:hover {
      background: linear-gradient(0deg, #F27600 0%, #E06D00 100%);
    }
  }
  &.orange {
    background: linear-gradient(0deg, #F27600 0%, #E06D00 100%);
    &:hover {
      background: linear-gradient(0deg, #00B842 0%, #00A83C 100%);
    }
  }
  &.lightgreen {
    background: linear-gradient(0deg, #00B842 0%, #00A83C 100%);
    &:hover {
      background: linear-gradient(0deg, #009D8B 0%, #007B6D 100%);
    }
  }
}

.btn-signup {
  &.is-supplier {
    color: #fff;
    border-color: $color-supplier;
    background-color: $color-supplier;
  }
  &.is-buyer {
    color: #fff;
    border-color: $color-buyer;
    background-color: $color-buyer;
  }
}

.btn-tag {
  @extend .btn-signup;
  padding:3px 5px;
  font-size: 10px;
  line-height: 1;
  border-radius: 20px;
  box-shadow: none;
  @include media-breakpoint-up(md) {
    padding: 7px 12px;
    font-size: 14px;
    line-height: 1;
    border-radius: 20px;
  }
}

.btn-favorite {
  width: 120px;
  max-width: 120px;
  padding: 5px 14px 5px 13px;
  color: $color-text-gray;
  font-size: 14px;
  line-height: 1;
  border-radius: 20px;
  background-color: #fff;
/*
  &.is-animation {
    .far,
    .fas {
      animation: favorite 0.5s ease 0s 1 normal;
      animation-fill-mode: forwards;
    }
  }
*/
  .far,
  .fas {
    margin-right: 8px;
  }
  .fas {
    position: static;
    display: none;
    font-size: 14px;
    transform: translateY(0);
  }
  &.is-active {
    color: #fff;
    background-color: $primary-color;
    .far {
      display: none;
    }
    .fas {
      display: inline-block;
    }
  }
}

@keyframes favorite {
    0% {
        transform: translateY(0) rotateY(0);
    }
    50% {
        transform: translateY(-10px) rotateY(180deg);
    }
    100% {
        transform: translateY(0) rotateY(0);
    }
}

.btn-more {
  margin: 10px;
  text-align: center;
  .fas {
    margin-left: 10px;
    font-size: 14px;
  }
  button {
    transition: all 0.2s ease-in-out;
    &:hover {
      color: $primary-color;
    } 
  }
}

.btn-file-upload {
  position: relative;
  width: 132px;
  height: 30px;
  background-color: #fff;
  color: #000;
  border: 2px solid $color-border-gray;
  border-radius: 4px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: #fff;
    background-color: #000;
    border-color: #000;
  }
  span {
    display: block;
    width: 132px;
    height: 26px;
    font-size: 12px;
    line-height: 26px;
  }
  input {
    height: 30px;
  }
}
.btn-add {
  width: 140px;
  height: 36px;
  padding: 0;
  background-color: $primary-color;
  color: #fff;
  font-size: 12px;
  line-height: 32px;
  border: 2px solid $primary-color;
  border-radius: 4px;
  text-align: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: #fff;
    background-color: $primary-color;
    border-color:$primary-color;
  }

}
.btn-remove {
  width: 140px;
  height: 36px;
  padding: 0;
  background-color: #fff;
  color: #000;
  font-size: 12px;
  line-height: 32px;
  border: 2px solid $color-border-gray;
  border-radius: 4px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: #fff;
    background-color: #000;
    border-color: #000;
  }
}

.btn-side-sp {
  overflow: hidden;
  position: fixed;
  bottom: 20px;
  right: 15px;
  z-index: 1000;
  display: block;
  width: 50px;
  height: 50px;
  padding: 0;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 1px 10px 1px rgba(0,0,0,0.2);
  .fas {
    color: $primary-color;
  }
  @include media-breakpoint-up(md) {
    display: none !important;
  }
}

.btn-clip {
  position: relative;
  margin: 0 auto;
  color: $primary-color;
  font-weight: bold;
  text-align: center;
  border: 2px solid $primary-color;
  cursor: pointer;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 4px;

  
  a {
    position: relative;
    z-index: 2;
    display: block;
    width: 46px;
    height: 46px;
    .fas {
      font-size: 24px;
    }
  }
}

.btn-topBack {
  max-width: 310px;
  padding: 12px;
  color: #3B4043;
  border: 1px solid #3B4043;
  background-color: transparent;
  .material-icons {
    right: auto;
    left: 16px;
    
  }
}
.is-en {
  .btn {
    padding-top: 12px;
    padding-bottom: 12px;
    &.focus {
      box-shadow: none !important;
    }
    &:hover {
      border-color: $color-en !important;
      background-color: $color-en !important;
      box-shadow: none !important;
      svg,
      span {
        opacity: 0.5 !important;
      }
    }
    .material-icons {
      position: absolute;
      top: 50%;
      right: 10px;
      font-size: 20px;
      transform: translateY(-50%);
    }
  }
  .btn-primary {
    &:hover {
      background-color: $color-en !important;
      box-shadow: none !important;
      span {
        opacity: 0.5 !important;
      }
    }
  }
  .btn-login {
    &:hover {
      border-color: $color-en !important;
    }
  }
  .btn-logout {
    &:hover {
      background-color: #3B4043 !important;
      border-color: #3B4043 !important;
    }
  }
  .btn-mypage {
    padding-right: 35px !important;
    color: #fff;
    &:hover {
      color: #fff;
      border-color: $color-en !important;
      background-color: $color-en !important;
    }
  }
  .btn-topBack {
    max-width: 310px;
    padding: 12px;
    color: #3B4043;
    border: 1px solid #3B4043;
    background-color: transparent;
    .material-icons {
      right: auto;
      left: 10px;
      
    }
  }
}

.url-btn {
  display: inline-block;
  margin-right: 5px;
  color: #000;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 2px 6px;
  &:hover {
    color: #fff;
    background-color: #006c27;
    border-color: #006c27;
  }
}
.main--events-detail .user-info .url-btn {
  text-decoration: none;
  border-color: #ff6589 !important;
  &:hover {
    color: #fff !important;
    background-color: #ff6589 !important;
  }
}
.user-info__url .url-btn {
  text-decoration: none !important;
  border-color: #50b3fa !important;
  &:hover {
    color: #fff !important;
    background-color: #50b3fa;
  }
}




