* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ol,
ul
{
  list-style: none;
}

input,
button,
textarea {
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
}

input[type="hidden"] {
    display: none;
}

textarea {
  vertical-align: top;
}

select{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus{
    outline: none;
  }
}

select::-ms-expand {
  display: none;
}

input::-ms-clear {
    visibility:hidden
}

img {
  border: none;
  vertical-align: top;
}

tbody {
  th{
    font-weight: normal;
  }
}
