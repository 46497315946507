body {
  font-family:'Noto Sans JP', "游ゴシック体", "Yu Gothic", "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
  font-size: 16px;
}

a {
  color: $primary-color;
  &:hover {
    color: darken($primary-color, 15%);
  }
}

.main{

}

@include placeholderColor(#000000);

input,
textarea,
select {
  border: none;
  font-weight: normal;
}

[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
[type='radio'],
[type='checkbox'],
[type='date'],
textarea,
.input,
select {

}

[type='text'],
[type='password'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='date'],
textarea{

}

/*
.win.firefox {
  [type='number']{
    height: 2.6rem;
  }
}
*/

