/** - - - - - - - - - - - -
 * modal
 - - - - - - - - - - - - */
.modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;

  &__contents {
    overflow: auto;
    position: fixed;
    z-index: 2;
    top: 50%;
    left: 50%;
    width: calc(100% - 40px);
    max-width: 1120px;
    max-height: 100vh;
    transform: translate(-50%, -50%);
    &--search {
      max-height: 90%;
      border-radius: 4px;
    }
  }

  &__inner {
    padding: 30px 20px 40px;
    text-align: center;
    letter-spacing: 0.05em;
    background-color: #fff;
    border-radius: 4px;

    @include media-breakpoint-up(md) {
      padding: 30px 40px 40px;
    }
    
    & > * {
      &:last-child() {
        margin-bottom: 0;
      }
    }
  }

  &__ttl {
    margin: 10px 0 30px;
    font-size: 24px;
    line-height: 1.4;
  }

  &__txt {
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 1.85;

    a {
      color: #000;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__block {
    padding: 10px;
    background-color: $white;
    border-radius: $border-radius-base;
  }

  &__bg {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  &__close {
    display: block;
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 10px;
      color: $color-close;
      font-size: 22px;
      cursor: pointer;
  }

  .checkboxHasLink {
    margin: 40px 0 10px;
    text-align: left;
  }
  
  .msgBlock {
    display: none;
    color: #000;
    font-size: 16px;
    margin-top: -24px;
    margin-bottom: 16px;
    
    &.is-error {
      display: block;
      color: $danger-color;
    }
  }

  /* 確認用 */
  &.is-show {
    display: block;
  }
  
  .grid--2col {
    width: 400px;
    margin: 0 auto;
    
    &__item {
      width: calc(50% - 10px);
    }
    
    [class*='btn'] {
      width: 100%;
      max-width: 190px;
      height: 50px;
      line-height: 46px;
    }
  }
  
  &--s {
    .modal__contents {
      width: calc(100% - 30px);
      @include media-breakpoint-up(md) {
        width: 500px;
      }
    }
    .modal__inner {
      padding: 30px;
    }
    .modal__txt {
      text-align: left;
      a {
        color: $primary-color;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .md-form {
      margin-bottom: 30px;
      .prefix {
        top: 10px;
        left: 0;
      }
      label {
        top: -3px;
      }
      input {
        padding-bottom: 10px;
      }
      .fas {
        font-size: 20px;
        &::before {
          color: $color-text-gray;
        }
        &.active {
          &::before {
            color: $primary-color;
          }
        }
      }
    }
    .close {
      position: absolute;
      top: 14px;
      right: 14px;
    }
    .form {
      .md-form input[type='text']:not(.browser-default),
      .md-form input[type='password']:not(.browser-default),
      .md-form textarea.md-textarea {
        &.form-error {
          border-color: $error-color !important;
          & + label {
            color: lighten($error-color, 15%);
          }
        }
      }
      .form-error {
        .sod_select {
          border-color: $error-color !important;
          .sod_label {
            color: lighten($error-color, 15%);
          }
          &::after {
            background-color: lighten($error-color, 15%);
          }
        }
      }
      .msgBlock {
        font-size: 14px;
      }
    }
  }
}

.fade-in.mui-enter {
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}

.fade-in.mui-enter.mui-enter-active {
  opacity: 1;
}

.fade-out.mui-leave {
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  opacity: 1;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}

.fade-out.mui-leave.mui-leave-active {
  opacity: 0;
}

#modal-word-order {
  .notes {
    font-size: 14px;
    color: $color-text-gray2;
    text-align: left;
  }
}
//ティザーファイルダウンロード
.modal-file-download {
  .row {
    max-width: 640px;
    margin: 0 auto;
  }
  .col {
    padding: 0 10px;
  }
  .btn {
    max-width: 298px;
    height: 64px;
    padding: 0;
  }
  .btn-download-food {
    line-height: 64px;
    margin-bottom: 10px;
  }
  .btn-download-non-food {
    padding-top: 7px;
    .fas {
      right: 13px;
    }
  }
}
//ティザーモーダル 確認
.teaser-contacts,
.modal-contacts {
  .contacts-confirm {
    max-width: 600px;
    margin: 0 auto;
    
    .form-control {
      overflow: hidden;
      height: auto;
      text-align: left;
      dt {
        float: left;
        font-weight: normal;
      }
    }
    &__name,
    &__contant-info,
    &__type,
    &__tel {
      dt {
        padding-right: 50px;
      }
    }
    &__message {
      &.form-control{
        dt {
          float: none;
          margin-bottom: 30px;
        }
      }
    }
  }
}

//ティザーモーダル 値合わせ送信完了
.modal-success {
  br {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
  }
  .modal__ttl {
    @include media-breakpoint-up(md) {
      padding-top: 100px;
    }
  }
  .modal__inner {
    @include media-breakpoint-up(md) {
      padding-bottom: 100px;
    }
  }
}

.modal-signup {
  .signup-finish-txt {
    display: none;
    text-align: left;
    font-size: 14px;
  }
}
.modal-suspend {
  .btn {
    padding: 18px;
  }
}

.modal-area-search {
  .area-search {
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
  }
  .area-search-map {
    position: relative;
    z-index: 10;
    img {
      opacity: 0;
    }
  }
  .active-map {
    img {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: none;
      
      &.is-hover,
      &.is-active {
        display: block ;
      }
    }
    .base-map {
      display: block;
      z-index: 1;
    }
  }
  .form {
    position: relative;
    z-index: 2;
    width: 293px;
    margin: 0 auto;
    text-align: left;
    
    .sod_select .sod_list {
      max-height: 170px !important;
    }
  }
  label {
    position: static;
    color: $primary-color;
    font-size: 12px;
  }
  .btn {
    max-width:293px;
    margin-top: 30px;
    .fas {
      font-size: 14px;
    }
  }
}
.modal-delete-message-comfirm {
  .btn-box {
    display: flex;
    justify-content: center;
    margin-bottom: 0 !important;
    
    .btn{
      max-width: calc(50% - 20px);
      &.btn-cansel {
        
      }
      &.btn-delete {
        
      }
    }
  }
}

.modal-discerning-search {
  .modal__ttl {
    margin-bottom: 0;
  }
  .modal__txt {
    text-align: center;
  }
  .discerning-search {
    max-width: 380px;
    margin: 0 auto 20px;
    text-align: left;
    .custom-control {
      margin: 0 0 15px;
    }
    &__block {
      padding-top: 15px;
      label {
        font-weight: bold;
      }
      border-top: 1px solid $color-border-gray;
      .u-ml20 {
        margin-left: 20px;
      }
    }
    &__subtitle {
      font-size: 14px;
    }
  }
  .row.custom-border-top {
    margin-right: 0;
    padding-top: 15px;
    border-top: 1px solid $color-border-gray;
  }
  .col-6 {
    padding-right: 0;
    @include media-breakpoint-down(sm) {
      width: 100%;
      max-width: inherit;
      flex: inherit;
    }
  }
  .btn {
    max-width:293px;
    @include media-breakpoint-down(sm) {
      padding-left: 20px;
      padding-right: 20px;
    }
    .fas {
      font-size: 14px;
    }
  }
  .discerning-select-area {
    display: flex;
    input {
      padding-bottom: 0;
    }
    &:last-of-type {
      margin-bottom: 15px;
    }
    > div {
      &:nth-child(1) {
        margin-bottom: 10px;
        padding-top: 8px;
      }
      &:nth-child(2) {
        display: none;
        position: relative;
        flex: 1;
        margin: 0 0 0 25px;
      }
    }
    .md-form {
      margin: 0;
    }
    &.is-active {
      > div {
        &:nth-child(2) {
          display: block;
        }
      }
    }
  }
  .toolTip-hover__balloon--left {
    @include media-breakpoint-up(sm) {
      left: 40px;
      right: calc(-100% + 15px);
    }
  }
  .toolTip-hover__balloon--right {
    @include media-breakpoint-up(sm) {
      left: calc(-100% + 40px);
      right: 15px;
      &:after {
        left: calc(50% + 30px);
      }
    }
  }
}

.modal-buyer-search {
  .buyer-search {
    &__block {
      max-width: 380px;
      margin: 0 auto 20px;
      text-align: left;
      padding-top: 15px;
      border-top: 1px solid $color-border-gray;
      .u-ml20 {
        margin-left: 20px;
      }
      label {
        color: #000;
        font-size: 14px;
      }
      .custom-control {
        margin: 0 0 15px;
      }
      .col-6 {
        padding-right: 0;
      }
    }
    &__subtitle {
      font-size: 14px;
    }
  }
}

.modal-buyer-search,
.modal-supplier-search {
  .modal__txt {
    max-width: 294px;
    margin: 0 auto; 
  }
  
  .md-form {
    position: relative;
    max-width: 294px;
    margin: 0 auto 30px; 
    .form-control {
      margin: 0 auto;
      &.js-supplier-search-keyword {
        padding-right: 30px;
        box-sizing: border-box;
      }
    }
    .fa-search {
      position: absolute;
      bottom: 10px;
      right: 10px;
      z-index: 1;
      font-size: 14px;
      &::before {
        color: #000;
      }
    }
  }
  .js-supplier-search-food-category,
  .js-set-address {
    max-width: 294px;
    margin: 0 auto; 
  }
  .checkbox-list {
    .checkbox {
      font-size: .875rem;
      display: -webkit-inline-box;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      width: 33%;
      margin: .5em 0;
      padding-right: 5px;
      .custom-control {
        padding-left: 0;
        margin-bottom: 0;
      }
    }
  }
  .font-notes {
    text-align: left;
  }

  .sod_select .sod_list {
    max-height: 170px !important;
  }
  label {
    color: $primary-color;
    font-size: 12px;
  }
  .btn {
    margin-top: 30px;
  }
}
.modal-user-message,
.modal-message,
.modal-buyer-message {
  .sod_select .sod_list {
    max-height: 170px !important;
  }
  .md-form textarea {
    width: 100%;
    height: 160px;
    padding: 18px;
    font-size: 14px;
    border: 2px solid $color-text-gray4;
    border-radius: 4px;
    box-sizing: border-box;
    
    @include placeholderColor(#757575);
    
    &:focus {
      outline: none;
    }
  }
}

.modal-region {
  .modal__contents {
    max-width: 500px;
  }
  .modal__inner {
    padding: 0;
  }
  .modal__ttl {
    font-weight: bold;
    margin-bottom: 20px;
  }
  &__head {
    display: flex;
    margin-bottom: 20px;
    @include media-breakpoint-down(sm) {
      display: block;
    }
    &-ttl {
      margin-bottom: 10px;
      font-size: 12px;
      text-align: left;
    }
    &-txt {
      margin-bottom: 0;
      font-size: 16px;
      text-align: left;
    }
  }
  &__dep,
  &__co {
    width: 50%;
    padding: 15px;
    border-bottom: 1px solid #EEE;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
  &__dep {
    img {
      width: 34px;
      float: left;
      margin-right: 10px;
    }
  }
  &__co {
    border-left: 1px solid #EEE;
    @include media-breakpoint-down(sm) {
        border-left: none;
    }
    .modal-region__head-txt {
      font-weight: bold;
    }
    img {
      margin-right: 10px;
    }
  }
  &__list {
    display: flex;
    li {
      width: 33.3%;
      border-left: 1px solid #EEE;
      &:first-child {
        border: none;
      }
      span {
        display: block;
        &.region-num  {
          display: inline;
        }
      }
      a {
        color: #00B842;
        font-size: 24px;
        text-decoration: underline;
        &.is-nolink {
          cursor: default;
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  &__bottom {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 12px 20px;
    text-align: right;
    line-height: 1;
    border-top: 1px solid #EEE;
    a {
      margin-left: 10px;
      color: #00B842;
      font-size: 24px;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.is-en {
  .modal .close,
  .modal__close {
    color: #3B4043;
    font-size: 24px;
    opacity: 1;
  }
  #modal-signup-supplier-en,
  #modal-login-en,
  #modal-reset-password-en {
    .modal__inner {
      padding: 20px;
      @include media-breakpoint-down(sm) {
        padding: 20px 12px;
      }
    }

    .modal__ttl {
      margin-bottom: 20px;
    }
    .btn {
      padding: 12px 45px;
      box-shadow: none !important;
      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }
    }
    .btn.is-disabled {
      cursor: default;
      background-color: #CDD6DD !important;
      .waves-ripple {
        display: none !important;
      }
      &:hover {
        span {
          opacity: 1 !important;
        }
      }
    }
    input[type='text'],
    input[type='password'] {
      padding: 7px 12px;
      border: 1px solid #727C8E !important;
      border-radius: 4px;
      background-color: #fff;
      box-sizing: border-box;
      box-shadow: none !important;
      &::placeholder {
        color: rgba(59, 64, 67, 0.5);
      }
    }
    label {
      top: -25px;
      margin: 0 5px 0 0;
      color: #727C8E;
      font-size: 14px !important;
      transform: translateY(0) scale(1) !important;
    }
    .md-form + .md-form {
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }
  #modal-reset-password-en {
    .md-form {
      margin-bottom: 0;
      input {
        margin-bottom: 0;
      }
    }
  }
   #modal-signup-supplier-en {
      .modal__ttl {
        margin-bottom: 50px;
      }
      .md-form {
        margin-bottom: 40px;
      }
      .msgBlock {
        margin-top: -25px;
        margin-bottom: 25px;
      }
   }
   #modal-login-en {
      .modal__txt {
        margin-bottom: 70px;
        @include media-breakpoint-down(sm) {
          margin-bottom: 30px;
        }
      }
   }
  .modal .msgBlock {
    margin-top: -15px;
  }
}
.is-nagano-mall {
  .modal--s {
    .modal__txt a {
      color: $mall-color2;
      transition: all 0.3s;
      &:hover {
        color: $mall-hover;
        text-decoration: underline;
      }
    }
  }
}

#modal-login-mall {
  text-align: left;
  .form {
    text-align: left;
  }
  #login-password {
    box-sizing: border-box !important;
    padding: 7px 12px;
    border: 1px solid #512700 !important;
    border-radius: 4px !important;
    background-color: #fff;
    box-shadow: none !important;
  }
  .btn-login {
    border-color: $mall-color3 !important;
    border-radius: 0;
    border-width: 2px;
    background-color: transparent !important;
    span {
      color: $mall-color3;
    }
    &:hover {
      span {
        color: #fff !important;
      }
      border-color: $mall-hover !important;
      background-color: $mall-hover !important;
    }
  }
  .btn-login.is-disabled {
    border-color: #969696 !important;
    background-color: #969696 !important;
    span {
      color: #fff !important;
    }
  }
}
.is-kitchencar {
  .modal-signup {
    .form-control {
      box-sizing: border-box !important;
    } 
  }
}
.modal-registration-products {
  &.modal--s .modal__contents {
    max-width: 600px;
  }
  .grid-wrap {
    display: flex;
    @include media-breakpoint-down(sm) {
      display: block;
    }
    .btn {
      max-width: calc(50% - 10px);
      margin-bottom: 0 !important;
      padding: 18px 16px;
      @include media-breakpoint-down(sm) {
        max-width: 100%;
        & + .btn {
          margin-top: 10px;
        }
      }

      .fas {
        right: 11px;
      }
    }
  }
}