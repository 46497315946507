/** - - - - - - - - - - - -
 * footer
 - - - - - - - - - - - - */
.footer {
  .is-en & {
    background-color: #fff;
    .footer__inner {
      max-width: 1236px;
    }
    .footer__link {
        @include media-breakpoint-down(sm) {
          padding: 0 24px;
        }
    }
    .footer__link > li:nth-child(1), .footer__link > li:nth-child(2) {
      flex-grow: 0; 
      padding-left: 50px;
      @include media-breakpoint-down(sm) {
        padding-left: 0;
      }
    }
    .footer__link > li:nth-child(1) ul li:first-child, .footer__link > li:nth-child(2) ul li:first-child {
      font-weight: normal;
    }
    .footer__link > li:last-child {
      @include media-breakpoint-down(sm) {
        width: 50%;
        padding-top: 0;
        ul {
          display: block;
          li {
            width: 100%;
            margin-bottom: 5px;
            a {
              padding: 3px;
              border: none;
            }
          }
        }
      }
    }
    .footer__link > li ul a {
      @include media-breakpoint-down(sm) {
        font-size: 14px;
      }
    }
    .footer__link > li ul li {
      margin-bottom: 10px;
      @include media-breakpoint-down(sm) {
        margin-bottom: 5px;
      }
    }
    .footer__link > li:last-child a {
      font-size: 14px;
    }
    .footer__link > li ul a {
      @include media-breakpoint-down(sm) {
        text-align: left;
      }
    }
    .site-logo {
      margin-bottom: 10px;
    }
    .footer__logo {
      @include media-breakpoint-down(sm) {
        border-bottom: none;
      }
      p {
        white-space: normal;
        word-break: break-word;
        line-height: 1.8;
        @include media-breakpoint-down(sm) {
          padding: 0 24px;
          text-align: left;
        }
      }
    }
    .grobal-link {
      max-width: 1236px;
      margin: 0 auto;
      @include media-breakpoint-down(sm) {
        display: block;
        a {
          padding: 0 12px;
        }
      }
    }
    .grobal-list {
      @include media-breakpoint-down(sm) {
        padding-top: 10px;
        justify-content: center;
      }
    }
    .copy-wrap {
      @include media-breakpoint-down(sm) {
        padding-top: 15px;
        border-top: 1px solid $color-border-gray;
      }
    }
    .copyright {
      margin-top: -10px;
      line-height: 1;
      color: #3B4043;
      opacity: 0.5;
      @include media-breakpoint-down(sm) {
        margin: 15px 0 0;
      }
    }
  }


  padding: 0 0 20px;
  text-align: center;
  border: 1px solid $color-border-gray;
  &.is-teaser {
    margin-left: 0;
    padding: 40px 15px 20px;
    .site-logo {
      margin-bottom: 30px;
    }
    p {
      margin-bottom: 30px;
      font-size: 14px;
    }
    .copyright {
      margin-bottom: 0;
      font-size: 12px;
    }
  }
  
  @include media-breakpoint-up(md) {
    padding: 40px 20px 20px;
    .is-fixed + & {
      margin-left: 0;
//       padding: 40px 20px 20px 280px;
      &__link {
        flex-wrap: wrap;
      }  
    }
  }

  .site-logo {
    display: inline-block;
    max-width: 146px;
    margin-bottom: 20px;
    color: #000;
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
    }
  }
  p {
    margin-bottom: 0;
    font-size: 12px;
    line-height: 2;
    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
      font-size: 14px;
    }
    span {
      display: block;
      margin-bottom: 25px;
      @include media-breakpoint-up(md) {
        margin-bottom: 30px;
      }
    }
  }
  
  //ティザー以外
  &__inner {
    display: block;
    max-width: 767px;
    margin: 0;
    @include media-breakpoint-up(md) {
      max-width: 850px;
      display: flex;
      margin: 0 auto 40px;
    }
  }
  &__logo {
    width: 100%;
    padding: 20px 0;
    text-align: center;
    border-bottom: 1px solid $color-border-gray;
    
    @include media-breakpoint-up(md) {
      width: 241px;
      padding: 0;
      text-align: left;
      border-bottom: none;
    }
    p {
      margin-bottom: 0;
      white-space: nowrap;
      word-break: normal;
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
      span {
        margin-bottom: 5px;
        @include media-breakpoint-up(md) {
          margin-bottom: 5px;
        }
      }
    }
  }
  &__link {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    padding-top: 20px;
    @include media-breakpoint-up(md) {
      flex-wrap: nowrap;
      padding-top: 0;
      padding-left: 30px;
    }
    & > li {
      width: 50%;
      padding-left: 0;
      @include media-breakpoint-up(md) {
        width: auto;
        padding-left: 40px;
        @media (max-width: 850px) {
          padding-left: 20px;
        }
        &:nth-child(1),
        &:nth-child(2) {
          padding-left: 20px;
          flex-grow: 1;
          ul {
            li {
              &:first-child {
                font-size: 14px;
                font-weight: bold;
                @media (max-width: 850px) {
                  font-size: 12px;
                  word-spacing: normal;
                }
              }
            }
          }
        }
      }
      ul {
        li {
          &:first-child {
            font-size: 12px;
          }
        }
        a {
          display: block;
          padding: 3px;
          font-size: 12px;
          @include media-breakpoint-up(md) {
            display: inline;
            padding: 0;
            font-size: 14px;

            @media (max-width: 850px) {
              font-size: 12px;
              word-spacing: normal;
            }
          }
        }
      }
      &:last-child {
        width: 100%;
        padding-top: 20px;
        @include media-breakpoint-up(md) {
          width: auto;
          padding-top: 0;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          li {          
            width: 50%;
          }
          @include media-breakpoint-up(md) {
            display: block;
            li {
              width: auto;
            }
          }
        }
        a {
          padding: 10px;
          font-size: 12px;
          border-bottom: 1px solid $color-border-gray;
          @include media-breakpoint-up(md) {
            padding: 0;
            border-bottom:none;
          }
        }
        li:nth-child(1),
        li:nth-child(3) {
          a {
            border-right: 1px solid $color-border-gray;
            @include media-breakpoint-up(md) {
              border-right:none;
            }
          }
        }
        li:nth-child(1),
        li:nth-child(2) {
          a {
            border-top: 1px solid $color-border-gray;
            @include media-breakpoint-up(md) {
              border-top:none;
            }
          }
        }
      }
    }
    li {
      text-align: center;
      @include media-breakpoint-up(md) {
        width: auto;
        text-align: left;
      }
    }
    p {
      margin-bottom: 5px;
      color: $color-text-gray2;
      font-size: 12px;
      @include media-breakpoint-up(md) {
        margin-bottom: 5px;
        font-size: 12px;
      }
    }
    a {
      color: #000;
      font-size: 14px;
      &:hover {
        color: $primary-color;
      }
    }
  }
  
  .footer-bnr-wrap {
    padding: 0 15px 5px;
    @include media-breakpoint-up(md) {
      max-width: 850px;
      margin: -100px auto 10px;
      padding: 0 0 0 290px;
      text-align: right;
    }
  }
  .footer-bnr {
    @include media-breakpoint-up(md) {
      padding: 0;
      transition: all 0.5s;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  
  .copyright {
    margin-bottom: 0;
    font-size: 12px;
  }
}
//キッチンカーメッセージ用
.is_kitchencar {
&.footer {
  &__inner {
    max-width: 870px !important;
    padding: 0 20px;
  }
  .page-top {
    .material-icons {
      display: block;
      color: $kit-color1;
      font-size: 24px;
    }
  }
  &__logo {
    .site-logo {
      span {
        letter-spacing: 0.08em;
      }
      svg {
        width: 100%;
      }
    }
  }
  &__link {
    &-ttl {
      font-weight: bold;
    }
    & > li {
      &:nth-child(1) {
        ul {
          & + ul {
            margin-top: 20px;
          }
        }
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
      &:nth-child(1),
      &:nth-child(2) {
        ul {
          li {
            &:first-child {
              a {
              font-weight: normal;
              }
            }
          }
        }
      }
      @include media-breakpoint-down(sm) {
        .resign {
          border-right: 1px solid #e6e6e6;
        }
      }
    }
    a {
      &:hover {
        color: $mall-hover;
      }
    }
  }
  .grobal-link {
    max-width: 1236px;
    margin: 0 auto;
    @include media-breakpoint-down(sm) {
      display: block;
      a {
        padding: 0 12px;
      }
    }
  }
  .grobal-list {
    @include media-breakpoint-down(sm) {
      padding-top: 10px;
      justify-content: center;
    }
  }
  .copy-wrap {
    @include media-breakpoint-down(sm) {
      padding-top: 15px;
      border-top: 1px solid $color-border-gray;
    }
  }
  .copyright {
    margin-top: -10px;
    line-height: 1;
    color: #3B4043;
    opacity: 0.5;
    @include media-breakpoint-down(sm) {
      margin: 15px 0 0;
    }
  }
}
}