/** - - - - - - - - - - - -
 * form
 - - - - - - - - - - - - */
.step {
  margin: 2rem 0 3.5rem;
  &__list {
    counter-reset:li;
    @include media-breakpoint-up(md) {
      display: flex;
    }
    @include media-breakpoint-down(sm) {
      display: block;
    }
    
  }
  &__item {
    position: relative;
    margin-left: 10px;
    padding: 0 0 0 80px;
    color: $color-border-gray;
    @include media-breakpoint-down(sm) {
      padding-left: 70px;
      & + .step__item {
        margin-top: 16px;
      }
    }
    &:first-child {
      margin-left: 0;
      padding: 0 0 0 38px;
      &::before {
        left: 0;
      }
      &::after {
        content: none;
      }
    }
    &.is-current {
      font-weight: bold;
      color: #000;
      &::before{
        background-color: $site-color;
      }
    }
    &.is-checked {
      color: #000;
      &::before{
        content: "\f00c";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        background-color: #000;
        -webkit-font-smoothing: antialiased;
        padding-top: 8px;
        font-size: 13px;
      }
    }
    &.is-current,
    &.is-checked {
      @include media-breakpoint-down(sm) {
        margin-left: 0;
        padding-left: 38px;
        &::before {
          left: 0;
        }
        &::after {
          display: none;
        }
      }
    }
    &::before{
      content: counter(li);
      counter-increment: li;
      position: absolute;
      top: 0;
      left: 42px;
      width: 28px;
      height: 28px;
      padding-top: 5px;
      border-radius: 51%;
      color: #FFF;
      font-weight: bold;
      line-height: 1;
      text-align: center;
      background-color: $color-border-gray;
      @include media-breakpoint-down(sm) {
        left: 32px;
      }
    }
    &::after{
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 30px;
      height: 1px;
      border-top: solid 1px $color-border-gray;
      @include media-breakpoint-down(sm) {
        top: 0;
        left: 4px;
        width: 20px;
        height: 15px;
        border-top: none;
        border-left: solid 1px $color-border-gray;
        border-bottom: solid 1px $color-border-gray;
      }
    }
  }
}

.md-form label {
  font-size: 0.875rem;
}
.formSection {
  margin-bottom: 3em;
  &__inner {
    @media (min-width: 1200px) {
      width: calc(100% / 3 * 2 - 10px);
    }
  }
  &__history {
    margin: 100px 0 50px;
    textarea {
      display: block;
      width: 100%;
      height: 200px;
      margin: 0 auto;
      padding: 20px;
      border: 2px solid $color-border-gray;
      font-size: 14px;
      &:focus {
        outline: none;
      }
      @include placeholderColor(#969696);
    }
    .u-fs14 {
      color: #f00;
    }
  }
  &__ttl {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    font-weight: bold;
    .tag-required {
      margin-left: 10px;
    }
  }
  &__subTtl {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    font-weight: normal;
    .tag-required {
      margin-left: 10px;
    }
  }

  .telWrap {
      .col-4 {
          flex: 0 0 calc(20% - 12px);
          max-width: calc(20% - 12px);
          label {
              white-space: nowrap;
          }
          @include media-breakpoint-down(sm) {
              flex: 0 0 calc(33.3% - 12px);
              max-width: calc(33.3% - 12px);
              padding: 0 5px;
              &:first-child {
                  padding-left: 15px;
                  input {
                      padding-left: 0;
                  }
              }
              input {
                  padding-left: 10px;
              }
          }
      }
      .haifun {
        padding-top: 10px;
        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }
      }
      label {
          transform: translateY(-14px) scale(.8);
          font-size: 1rem;
      }
      input {
          &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
              color: #ccc;
          }
          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
             color: #ccc;
          }
          &::placeholder{ /* Others */
           color: #ccc;
          }
      }
  }
}
.toolTip-hover {
  position: relative;
  &__balloon {
    visibility: hidden;
    z-index: -1;
    position: absolute;
//     top: -6px;
    top: -20px;
    margin: 0 1rem 0 0;
    padding: 10px 20px 10px 54px;
    background-color: #FFF;
    font-size: 14px;
    line-height: 1.4;
    border-radius: 4px;
    box-shadow: 0 2px 11px 1px rgba(0,0,0,.1);
    &::before{
      content: "\f06a";
      position: absolute;
      top: 50%;
      left: 19px;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      color: $site-color;
      -webkit-font-smoothing: antialiased;
      font-size: 24px;
      transform: translateY(-50%);
    }
    &::after {
      content: "";
      position: absolute;
      left: 25px;
      bottom: -10px;
      border-top: solid 11px #FFF;
      border-right: solid 6px transparent;
      border-left: solid 6px transparent;
    }
    .toolTip-hover:hover & {
      visibility: visible;
      z-index: 10;
      animation: balloon .8s ease-in-out forwards;
      -webkit-animation: balloon .8s ease-in-out forwards;
    }
  }
}
.toolTip-checked {
  visibility: hidden;
  z-index: -1;
  position: absolute;
  top: -0.5rem;
  left: -144px;
  width: 300px;
  padding: 10px 20px 10px 54px;
  background-color: #FFF;
  font-size: 14px;
  line-height: 1.4;
  border-radius: 4px;
  box-shadow: 0 2px 11px 1px rgba(0,0,0,.1);
  &::before{
    content: "\f06a";
    position: absolute;
    top: 50%;
    left: 19px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: $site-color;
    -webkit-font-smoothing: antialiased;
    font-size: 24px;
    transform: translateY(-50%);
  }
  &::after {
    content: "";
    position: absolute;
    left: 25px;
    bottom: -10px;
    border-top: solid 11px #FFF;
    border-right: solid 6px transparent;
    border-left: solid 6px transparent;
  }
  .custom-control-input:checked:focus + .custom-control-label + & {
    visibility: visible;
    z-index: 10;
    animation: balloon .8s ease-in-out forwards;
    -webkit-animation: balloon .8s ease-in-out forwards;
  }
}

.md-form {
  margin-top: 0;
  &__toolTip {
    visibility: hidden;
    z-index: -1;
    position: absolute;
    top: 6px;
    right: 2px;
    margin-left: 4em;
    padding: 10px 20px 10px 54px;
    background-color: #FFF;
    font-size: 14px;
    line-height: 1.4;
    border-radius: 4px;
    box-shadow: 0 2px 11px 1px rgba(0,0,0,.1);
    &::before{
      content: "\f06a";
      position: absolute;
      top: 50%;
      left: 19px;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      color: $site-color;
      -webkit-font-smoothing: antialiased;
      font-size: 24px;
      transform: translateY(-50%);
    }
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -10px;
      border-top: solid 11px #FFF;
      border-right: solid 6px transparent;
      border-left: solid 6px transparent;
      margin-left: -6px;
    }
    .form-control:focus + .active + & {
      visibility: visible;
      z-index: 10;
      animation: balloon .8s ease-in-out forwards;
      -webkit-animation: balloon .8s ease-in-out forwards;
    }
  }
  &.is_lock {
    label.active {
      font-size: .875rem !important;
      transform: translateY(12px) !important;
    }
  }
}
.md-form-other {
  position: absolute;
  right: 15px;
  bottom: 0.125rem;
  width: calc(100% - 8rem);
  margin: 0;
}
.custom-select {
  margin: 1.5rem 0 1rem 0;
}
.form-control {
  padding-left: 0;
  border: none;
  border-bottom: 1px solid $color-border-gray;
}
.form-error {
  @include placeholderColor(lighten($error-color, 15%));
  border-color: $error-color;
}
.error-message {
  margin-bottom: 20px;
  color: $error-color;
}
.custom-control-input:checked~.custom-control-label::before {
  border-color: $site-color;
  background-color: $site-color;
}
.col-xl-4 .btn {
  display: block;
  @include media-breakpoint-up(md) {
    max-width: none;
  }
}
.btn-back {
  color: #FFF;
  background-color: $color-text-gray;
  .fas {
    right: auto;
    left: 16px;
  }
  &:hover {
    color: #fff;
  }
}
.custom-control {
  margin: 0.5rem 0 1rem 0;
  font-size: 0.875rem;
}
.custom-control-inline {
  min-width: 3.5rem;
  margin: 0 2rem 1rem 0;
}
.font-notes {
  margin-top: -1rem;
  margin-bottom: 1.5rem;
  font-size: 0.75rem;
  .txt-warning {
    display: block;
    color: #f00;
  }
}
@keyframes balloon {
  0%   {
    opacity: 0;
    transform: translateY(-100%) scale(0.2, 0.2);
  }
  60%   {
    opacity: 1;
    transform: translateY(-100%) scale(1, 1);
  }
  75%   {
    transform: translateY(-100%) scale(1.02, 1.05);
  }
  100%  {
    transform: translateY(-100%) scale(1, 1);
  }
}
@-webkit-keyframes balloon { /* Safari & Chrome */
  0%   {
    opacity: 0;
    -webkit-transform: translateY(-100%) scale(0.2, 0.2);
  }
  60%   {
    opacity: 1;
    -webkit-transform: translateY(-100%) scale(1, 1);
  }
  75%   {
    -webkit-transform: translateY(-100%) scale(1.02, 1.05);
  }
  100%  {
    -webkit-transform: translateY(-100%) scale(1, 1);
  }
}
.mdb-select.md-form,
.select-dropdown {
  font-size: 14px !important;
}
.dropdown-content li:first-child > span {
  color: #808080;
}
.dropdown-content li > span {
  color: #000;
}
.dropdown-content li:hover,
.dropdown-content li.active {
  background-color: $color-menu-hover;
  & > span {
    color: #808080;
  }
}
.file-field {
  display: flex;
  align-content: center;
  input.file-path {
    height: 30px;
    &:focus {
      outline: none;
    }
  }
  .file-path-wrapper {
    font-size: 14px;
  }
}

.select {
  min-height: 45px;
  .sod_select {
    width: 100%;
    padding-left: 0;
    border: none;
    border-bottom: 1px solid $gray-400;
    &::before {
      content: none;
    }
    &.focus {
      box-shadow: none;
    }
    .sod_label {
      font-size: 14px;
      font-weight: normal;
      opacity: 1 !important;
    }
    .sod_list_wrapper {
      width: 100%;
      margin: 0;
      font-size: 14px;
      border: none;
      box-shadow: $z-depth-1;
      .active {
        background-color: $color-menu-hover;;
      }
    }
  }
  select {
    opacity: 0;
  }
  &.is-placeholder {
    .sod_label {
      color: #757575;
      opacity: 0.5 !important;
    }
  }
}
.form-control[readonly] {
  background-color: #fff !important;
}

/** - - - - - - - - - - - -
 * validate error
 - - - - - - - - - - - - */
.validate-wrap {
  .validate-wrap__message {
    display: none;
    margin: 10px 0 20px;
    padding: 8px 10px 10px;
    background-color: $error-color;
    border-radius: 3px;
    color: #fff;
    font-size: .875rem;
  }
  &.is-error {
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -5px;
      left: -10px;
      right: -10px;
      bottom: -5px;
      border: 1px solid $error-color;
      border-radius: 3px;
      z-index: -1;
    }
    .validate-wrap__message {
      display: block;
    }
    p.validate-wrap__message {
      display: inline-block;
    }
  }
  .validate-wrap {
    &:before {
      display: none;
    }
    .validate-wrap__message {
      display: none;
    }
    &.is-error {
      &:before {
        display: block;
      }
      .validate-wrap__message {
        display: block;
      }
      p.validate-wrap__message {
        display: inline-block;
      }
    }
  }
}

.is-en {
  &.add-supplier,
  &.foodregistration{
    .upload {
      &__img {
        .delete {
          color: #f00;
        }
      }
    }
    .validate-wrap.is-error {
      &::before {
        content: none;
      }
      .select .sod_select,
      input,
      textarea {
        border-color: #f00 !important;
      }
      .select .sod_select {
        &::after {
          color: #f00 !important;
        }
      }
      &.error-border {
        &::before {
          content: '';
          border-color: #f00 !important;
        }
      }
    }
    .custom-checkbox label {
      padding-top: 5px;
    }
    .custom-control-label {
      color: #3B4043;
      &::before {
        border-color: #727C8E !important;
        box-shadow: none !important;
      }
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #00B842 !important;
    }
    .supplied_months {
      
      .custom-checkbox {
        margin: 0;
      }
    }
    .md-form .form-control:disabled {
      border-color: #CDD6DD !important;
      &::placeholder {
        color: rgba(#3b4043, 0.3) !important;
      }
    }
    .export-experience {
      &__ttl {
        font-size: 14px;
        font-weight: bold;
          span {
            display: block;
          }
      }
      .row + .row {
        margin-top: 0 !important;
      }
      .col-md-6 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    
    .container {
      max-width: 100%;
      padding: 0;
       @include media-breakpoint-down(sm) {
        padding: 0;
       }
      .container__inner,
      .l-registration-addfood ,
      .l-registration-addnonfood{
        max-width: 600px;
        margin: 0 auto;
      }
    }
    .main--foodregistration {
      margin-bottom: 80px;
    }
    .ttl-section {
      margin-top: 40px;
    }
    .form__ttl {
      font-size: 14px;
      font-weight: bold;
      align-items: flex-end;
      label {
        color: #3B4043;
      }
    }
    .input-txt-wrap {
      align-items: flex-end;
      margin: 0;
      .md-form {
        width: calc(100% - 130px);
        margin-bottom: 0;
        padding-right: 0;
      }
    }
    .input-txt {
      font-size: 12px;
      color: #3B4043;
      width: 130px;
      padding-left: 10px;
    }
/*
   .container{
     max-width: 600px;
   }
*/
   .means_of_transportation {
     .checkbox {
       display: block;
     }
     .custom-checkbox label {
       padding-top: 5px;
     }
   }
   .add-pr-movie {
     input {
       width: calc(100% - 40px);
     }
   }
   .case-size {
     .md-form {
       display: flex;
       align-items: flex-end;
       .md-form__unit {
         position: static;
         padding-left: 10px;
         color: #3B4043;
         font-size: 12px;
       }
     }
   }
    .btn-add,
    .btn-file-upload  {
      color: #fff;
      background-color: #727C8E;
      border-color: #727C8E;
      &:hover {
        color: #fff;
        background-color: $color-en;
        border-color: $color-en;
      }
    }
    .btn-add {
      padding: 0;
    }
    .btn-box--end {
      margin: 60px 0;
      text-align: center;
      .btn-back {
        margin-right: 10px;
      }
    }
  }
  .formSection {
    &__inner {
      width: 100%;
    }
    .ttl-section {
      text-align: center;
    }
    .txt-read {
      margin-bottom: 40px;
      text-align: center;
    }
    .form-control.form-control-unit {
      width: 100%;
    }
  }
  .form {
    &__ttl {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .box-btn {
      .btn {
        max-width: 310px;
        padding: 12px;
      }
       @include media-breakpoint-down(sm) {
         .btn-primary {
           max-width: 100%;
         }
       }
    }
  }
  .tag-required {
    line-height: 24px;
  }
  .md-form {
    margin-bottom: 20px;
    @include media-breakpoint-down(sm) {
      margin-top: 10px !important;
    }
  }
  .custom-checkbox {
    padding-left: 30px;
    label {
      margin-top: -4px;
    }
    .custom-control-label {
        &::before {
          width: 24px;
          height: 24px;
          left: -30px;
          border-color: #CDD6DD;
        }
        &::after {
          width: 24px;
          height: 24px;
          left: -30px;
        }
    }
    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }
  .contact .md-form input[type='text']:not(.browser-default).form-error,
  .contact .md-form textarea.md-textarea.form-error,
  .md-form input[type='text']:not(.browser-default).form-error,
  .md-form textarea.md-textarea.form-error {
    border-color: #f00 !important;
  }
  .error-message {
    color: #f00 !important;
  }
  .form-error .sod_select {
    border-color: #f00 !important;
    &::after {
      color: #f00 !important;
      background-color: transparent !important;
    }
  }
  input[type='text'],
  input[type='password'],
  input[type='number']
   {
    padding: 7px 12px;
    border: 1px solid #727C8E !important;
    border-radius: 4px !important;
    background-color: #fff;
    box-sizing: border-box !important;
    box-shadow: none !important;
    &::placeholder {
      color: rgba(59, 64, 67, 0.5);
    }
  }
  label {
    margin: 0 5px 0 0;
    color: #3B4043;
    font-size: 14px !important;
    transform: translateY(0) scale(1) !important;
    color: #727C8E;
  }
  .select .sod_select {
    padding: 12px;
    border: 1px solid #727C8E !important;
    border-radius: 4px;
    &:after {
      content: '\f0d7';
      font-family: 'Font Awesome 5 Free';
      font-size: 16px;
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);
    }
  }
  .md-textarea {
    padding: 7px 12px !important;
    border: 1px solid #727C8E !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    background-color: #fff;
    box-sizing: border-box !important;
    &::placeholder {
      color: rgba(59, 64, 67, 0.5);
    }
  }
}