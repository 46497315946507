/** - - - - - - - - - - - -
 * tag
 - - - - - - - - - - - - */
.tag {
  display: block;
  padding: 4px 8px;
  color: #000;
  font-size: 14px;
  background-color: $color-tag;
  border-radius: 4px;
}

.tag-month {
  display: block;
  min-width: 43px;
  padding: 4px 8px;
  color: $color-text-gray4;
  font-size: 14px;
  text-align: center;
  line-height: 1;
  border: 1px solid $color-border-gray;
  border-radius: 4px;
  .is-active & {
    color: #fff;
    background-color: #000;
    border-color: #000;
  }
}

.tag-required {
  padding: 1px 4px;
  color: #ff0000;
  font-size: 12px;
  line-height: 1;
  border: 1px solid #ff0000;
  border-radius: 2px;
}