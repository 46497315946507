/** - - - - - - - - - - - -
 * other
 - - - - - - - - - - - - */
.feature-banner {
  overflow: hidden;
  position: relative;
  height: 420px;
  padding: 30px 0 10px;
  background-color: $color-bg-green;
  border-top: 1px solid $color-border-gray;
  @media (max-width: 1140px) {
    height: auto;
  }
  @include media-breakpoint-up(md) {
     padding: 40px 0;
  }
  &__arrow {
    float: right;

    @include media-breakpoint-up(md) {
      position: absolute;
      z-index: 2;
      top: 42px;
      right: 20px;
    }
  }
  
  &__list {
    opacity: 0;
    height: 280px;
    position: absolute;
    top: 100px;
    left: 50%;
    margin-left: -459px;
    @media (max-width: 1140px) {
      position: static;
      margin-left: 0;
    }
    
    &.slick-initialized {
      opacity: 1;
      height: auto;
    }
  }
  &__ttl {
    margin-bottom: 25px;
    font-size: 20px;
    text-align: center;
  }
  &__item {
    padding: 0 7px;
    @include media-breakpoint-up(md) {
      padding: 0 10px;
    }
    img {
      width: 82.66vw;
      @include media-breakpoint-up(md) {
        width: 440px;
      }
    }
    a {
      display: block;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  .slick-list {
    overflow: visible;
  }
  .slick-prev {
    display: none !important;
  }
  .slick-next {
    overflow: hidden;
    position: relative;
    width: 76px;
    margin-top: 10px;
    margin-right: 15px;
    padding: 5px 22px 5px 5px;
    color: #000;
    font-size: 12px;
    transition: all 0.2s ease-in-out;
    @include media-breakpoint-up(md) {
      overflow: hidden;
      width: 66px;
      margin: 0;
      padding: 5px 22px 5px 5px;
      font-size: 14px;
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      height: 1px;
      background-color: #000;
      transition: all 0.2s ease-in-out;
    }
    &::before {
      width: 17px;
    }
    &::after {
      width: 5px;
      transform: rotate(45deg);
      transform-origin: center right;
    }
    
    &:hover {
      color: $primary-color;
      &::before,
      &::after {
        background-color: $primary-color;
      }
    } 
  }
}


/* flash */
.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: auto;
}

.reveal {
  z-index: 1006;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: none;
  min-height: inherit !important;
  height: auto !important;
  
  padding: 50px 30px;
  border-radius: 4px;
  background-color: #fff;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto;
  text-align: center;
  font-size: 14px;
}

.m-flash-message__inner img {
  margin-bottom: 35px;
}

.m-flash-message__img{
  display: none;
}
.m-flash-message--success{
  .m-flash-message__img--success{
    display: block;
  }
}
.m-flash-message--danger{
  .m-flash-message__img--danger{
    display: block;
  }
}

[data-whatinput='mouse'] .reveal {
  outline: 0;
}

@media print, screen and (min-width: 40em) {
  .reveal {
    min-height: 0;
  }
}

.reveal .column {
  min-width: 0;
}

.reveal > :last-child {
  margin-bottom: 0;
}

@media print, screen and (min-width: 40em) {
  .reveal {
    width: 580px;
    max-width: 75rem;
  }
}

.reveal.collapse {
  padding: 0;
}

@media print, screen and (min-width: 40em) {
  .reveal.tiny {
    width: 30%;
    max-width: 75rem;
  }
}

@media print, screen and (min-width: 40em) {
  .reveal.small {
    width: 50%;
    max-width: 75rem;
  }
}

@media print, screen and (min-width: 40em) {
  .reveal.large {
    width: 90%;
    max-width: 75rem;
  }
}

.reveal.full {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: none;
  height: 100%;
  min-height: 100%;
  margin-left: 0;
  border: 0;
  border-radius: 0;
}

@media screen and (max-width: 39.99875em) {
  .reveal {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    min-height: 100%;
    margin-left: 0;
    border: 0;
    border-radius: 0;
  }
}

.reveal.without-overlay {
  position: fixed;
}

/* pagetop */
.page-top {
  position: static;
  display: block;
  padding: 10px;
  color: #000;
  font-size: 12px;
  text-align: center;
  border-bottom: 1px solid $color-border-gray;
  .fas {
    display: block;
    color: $primary-color;
    font-size: 16px;
  }
  &.is-teaser {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 10;
    padding: 10px;
    color: #000;
    font-size: 14px;
    text-align: center;
    border-bottom:none;
  }

  @include media-breakpoint-up(md) {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 10;
    padding: 10px;
    color: #000;
    font-size: 14px;
    text-align: center;
    border-bottom:none;
  }

  &:hover {
    color: #000;
    .fas {
      color: $primary-color;
    }
  }
}

.modal-backdrop.show {
  z-index: 200;
}
.modal-footer {
  display: block;
  text-align: center;
}
.grecaptcha-badge {
  display: none !important;
}
.is-buyer {
  .tab {
    &__list {
      justify-content: flex-start;
    }
    &__item {
      width: calc(25% - 5px);
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
.tab {
  margin: 30px -20px 0;
  border-bottom: 1px solid #3B4043;
  @include media-breakpoint-down(sm) {
    overflow-x: auto;
    overflow-y: hidden;
  }
  &__list {
    display: flex;
    //justify-content: space-between;
    justify-content: flex-start;
    max-width: 940px;
    margin: 0 auto;
    padding: 0 10px;
    @include media-breakpoint-down(sm) {
      min-width: 447px;
    }
  }
  &__item {
    width: 215px;
    margin-left: 20px;
    &:first-child {
      margin-left: 0;
    }
    @media(max-width: 880px) {
      width: calc(25% - 5px);
      margin-left: 5px;
      img {
        max-width: 100%;
        
      }
    }
    @include media-breakpoint-down(sm) {
      min-width: 100px;
      margin-right: 8px;
      img {
        width: 80px;
      }
    }
  }
  a {
    position: relative;
    display: block;
    height: 70px;
    padding: 0 10px;
    line-height: 70px;
    color: #000;
    font-size: 14px;
    text-align: center;
    opacity: 0.2;
    border-radius: 4px 4px 0 0;
    border: 1px solid #3B4043;
    border-bottom: none;
    transition: all 0.3s;
    @include media-breakpoint-down(sm) {
      height: 45px;
      line-height: 45px;
      font-size: 10px;
    }
    &:hover,
    &.active {
      opacity: 1;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -2px;
        width: 100%;
        height: 2px;
        opacity: 1;
        background-color: #fff;
      }
    }
    img {
      vertical-align: middle;
    }
  }
}

  .tab-item-index {
      .ttl-page {
        padding-top: 70px;
        margin-bottom: 25px;
      }
      .txt-read {
        margin-bottom: 100px;
        text-align: center;
        a {
          text-decoration: underline;
          &:hover {
            color: $color-en;
          }
        }
      }
    .tab {
      margin: 0 -20px;
      border-bottom: 1px solid #3B4043;
      &.is-lang {
        .tab__list {
          justify-content: flex-start;
        }
      }
      &__list {
        display: flex;
        justify-content: space-between;
        max-width: 520px;
        margin: 0 auto;
      }
      &__item {
        width: calc(33.3% - 14px);
      }
      a {
        position: relative;
        display: block;
        height: 50px;
        padding: 0 10px;
        line-height: 50px;
        color: #3B4043;
        font-size: 16px;
        text-align: center;
        opacity: 0.2;
        border-radius: 4px 4px 0 0;
        border: 1px solid #3B4043;
        border-bottom: none;
        transition: all 0.3s;
        &:hover,
        &.active {
          color: #00B842;
          opacity: 1;
          &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -2px;
            width: 100%;
            height: 2px;
            opacity: 1;
            background-color: #fff;
          }
        }
      }
    }
    .tab-contents {
      &__list {
        display: none;
        &:first-child {
          padding-top: 0;
          display: block;
        }
      }
    }
  }
