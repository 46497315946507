/** - - - - - - - - - - - -
 * heading
 - - - - - - - - - - - - */
.ttl {
 max-height: 100%;
 font-size: 14px;
}

.ttl-page {
  @extend .ttl;

  margin-bottom: 35px;
  font-size: 24px;
  letter-spacing: 0.04em;
}

.ttl-section {
  @extend .ttl;
  margin-bottom: 25px;
  font-size: 24px;
}
.ttl-product {
  margin-bottom: 50px;
  text-align: center;
  .ttl-page {
    margin-bottom: 18px;
  }
}