/** - - - - - - - - - - - -
 * layouts
 - - - - - - - - - - - - */
body.is-fixed,
body.is-fixed-thread {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  .btn-side-sp {
    opacity: 0;
  }
}
body.is-fixed.is-nagano-mall {
  position: static;
}

//En
body.is-en {
  .btn-side-sp {
    transition: all 0.3s;
    right: 10px;
    bottom: -60px;
    &.is-active {
      bottom: 20px;
    }
  }
  .side-overlay {
    &__close {
      position: fixed;
      top: 50%;
      right: 10px;
      width: 50px;
      height: 50px;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0 3px 6px rgba(0,0,0,0.3);
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  
  .contents-wrap  {
    &.is-fixed {
      .btn-side-sp {
        opacity: 0;
      }
       @include media-breakpoint-up(md) {
         position: relative;
         padding-left: 0;
         .side {
           position: fixed;
           top: auto;
           bottom: 0;
           left: 0;
           z-index: 5;
         }
         &.is-bottom {
           .side {
             position: absolute;
           }
         }
       }
    }
    @include media-breakpoint-up(md) {
      display: block;
      flex-direction: row-reverse;
    }
    
    .side {
      width: 81.2%;
      .btn {
        text-align: center;
      }
     .login {
       padding: 12px 12px 10px;
       text-align: right;
       &.is-nologin {
         border-bottom: none;
       }
       .btn-mypage {
         max-width: 100%;
       }
       &__link {
         color: #3B4043;
         vertical-align: middle;
         .material-icons {
           margin-left: 10px;
           font-size: 20px;
           vertical-align: middle;
         }
       }
       .signup-block {
         width: 100%;
         &__bg {
           width: 100%;
         }
         .btn {
           width: calc(100% - 24px);
           height: 48px;
           margin-bottom: 0;
           line-height: 48px;
         }
         a {
           font-size: 12px;
         }
       }

      .side-link {
        margin-top: 10px;
        margin-bottom: 0;
        &__item {
          text-align: left;
          font-size: 14px;
          color: #000;
          a {
              display: block;
              padding: 5px 0;
              color: #000;
          }
        }
        .icon-new {
          margin-right: 10px;
          color: #f00;
        }
      }

     }
     .search {
       padding: 10px 12px 20px;
       text-align: right;
       border-bottom: none;
       .md-form {
         width: 100%;
         max-width: 100%;
         margin-bottom: 10px;
         .form-control {
           border: 1px solid #727C8E !important;
         }
       }
     }
     .side-menu {
       &__ttl {
         margin-bottom: 0;
         padding: 24px 12px 20px;
         color: #3B4043;
         border-bottom: 1px solid $color-border-gray;
         svg {
           margin-right: 5px;
         }
       }
       &__txt {
         width: calc(100% - 34px);
       }
       &__item {
         &:last-child {
           a {
              border-bottom: none;
           }
         }
         & > a {
           padding: 13px 12px 13px 6px;
           border-bottom: 1px solid $color-border-gray;
         }
         .material-icons {
           position: absolute;
           top: 50%;
           right: 12px;
           font-size: 20px;
           transform: translateY(-50%);
         }
         .fas {
           right: 12px;
         }
       }
       
       &__list {
        margin-bottom: 0;
       }
       &__thum {
         width: 34px;
         img {
            border: none;
            border-radius: 0;
          }
       }
       .grobal-link {
         padding: 10px 12px 30px;
       }
       .footer-link {
         margin-top: 30px;
         padding: 0 12px;
         li {
           margin-bottom: 10px;
         }
         a {
           color: #3B4043;
           font-size: 16px;
         }
         & + .footer-link {
           margin-top: 20px;
           a {
             font-size: 14px;
           }
         }
       }
     }
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
    .main {
      width: 100%;
      padding: 0 20px;
      @include media-breakpoint-down(sm) {
        padding: 0 12px;
      }
      
      @include media-breakpoint-up(md) {
        width: 100%;
        flex-grow: 1;
      }
    }
  }
  .fixd-signup-block {
    position: fixed;
    z-index: 100;
    right: 0;
    bottom: 20px;
    width: 280px;
    @include media-breakpoint-down(sm) {
      display: none;
    }
    img {
      width: 100%;
    }
    .btn {
      width: calc(100% - 24px);
    }
    .signup-block {
      overflow: hidden;
      width: 100%;
      height: 184px;
      background-color: #000;
      border-radius: 4px;
      box-shadow: 0 8px 30px rgba(0,0,0,0.3);
      a {
        font-size: 12px;
      }
      &__close {
        position: absolute;
        z-index: 2;
        top: 6px;
        right: 6px;
        width: 28px;
        height: 28px;
        margin: 0;
        border: 1px solid #fff;
        border-radius: 50%;
        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          fill:#fff;
        }
      }
    }
  }
}
body.is-kitchencar {
  .side {
    .side-menu.is-m {
      position: relative;
      margin-top: 8px;
      .icon-new {
        position: absolute;
        top: -9px;
        left: 8px;
      }
    }
  }
}
//naganoMall
body.is-nagano-mall,
body.is-kitchencar {
  .side {
    display: none;
    width: 72%;
    @include media-breakpoint-down(sm) {
      display: block;
    }
    .login {
      padding: 30px 20px;
      border:none;
    }
   .icon-new {
     margin-left: 10px;
     color: #f00;
   }
    .btn-login {
      height: 40px;
      padding: 0 45px;
      border: 2px solid $mall-color3;
      border-radius: 20px;
      line-height: 40px;
      text-align: center;
      span {
        color: $mall-color3;
      }
      &:hover {
        background-color: $mall-hover !important;
        border-color: $mall-hover !important;
        span {
          color: #fff;
        }
      }
    }

    .ttl-sub-section {
        padding: 0 20px;
        margin-bottom: 10px;
    }
    .side-menu__thum {
      width: 40px;
      img {
        border: none;
        border-radius: 0;
      }
    }
    .side-menu__list {
      padding: 0 20px;
    }
    .side-menu__item {
      border-top: 1px solid #F0F0F0;
      & > a {
        padding: 7px 0;
        align-items: center;
        &:hover {
          background-color: $mall-hover;
          span {
            color: #fff;
          }
        }
      }
      span {
        vertical-align: middle;
      }
      .material-icons {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        font-size: 14px;
        color: #000;
      }
    }
    .side-menu-list {
      padding: 0 0 60px;
      .ttl-sub-section {
        margin-bottom: 10px;
        padding: 0 20px;
      }
      .side-menu {
        border: none;
        &--s {
          a {
            padding: 7px 20px;
            font-size: 12px;
          }
        }
        &__link {
          padding: 10px 20px;
        }
      }
    }
  }
  .side-overlay {
    background-color: rgba($mall-color3, 0.8);
  }
  .category-serach-child-wrap-sp {
    .side-menu-sub-child,
    .side-menu-sub-sub-child,
    .side-menu-child {
      padding: 8px 20px;
      .side-menu__ttl {
        padding-left: 0;
      }
      li {
        border-bottom: 1px solid #F0F0F0;
      }
      a {
        padding: 13px 0;
        &:hover {
          color: #fff;
          .material-icons {
            color: #fff;
          }
        }
        span {
          vertical-align: middle;
        }
        .material-icons {
          position: absolute;
          top: 50%;
          right: 6px;
          font-size: 14px;
          transform: translateY(-50%);
        }
      }
    }
  }
}


//2カラムレイアウト
.contents-wrap {
  &.is-fixed {
     @include media-breakpoint-up(md) {
       position: relative;
       padding-left: 240px;
       .is-kitchencar & {
          padding-left: 0;
       }
       
       .side {
         position: fixed;
         top: auto;
         bottom: 0;
         left: 0;
         z-index: 5;
       }
       &.is-bottom {
         .side {
           position: absolute;
         }
       }
     }
  }
  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: row-reverse;
  }

  .main {
    @include contentsPadding;
    width: 100%;
    padding: 0 15px;
    @include media-breakpoint-up(md) {
      width: calc(100% - 240px);
      flex-grow: 1;
    }

  }
  .side {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 78.66%;
    height: 100%;
    background-color: #fff;
    border-right: 1px solid $color-border-gray;
    transform: translateX(-105%);
    transition: transform 0.3s ease-in-out;
    will-change: transform;

    @include media-breakpoint-up(md) {
      position: static;
      width: 240px;
      height: auto !important;
      opacity: 1;
      transform: translateX(0);
    }
    
    &.is-active {
      transform: translateX(0);
    }
    &.is-sub {
      transform: translateX(0);
      .side__inner {
        transform: translateX(-105%);
      }
    }
    &-overlay {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 5;
      display: none;
      background-color: rgba(0,0,0,0.5);
      cursor: pointer;
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
    &__inner {
      overflow: auto;
      position: relative;
      z-index: 5;
/*
      height: 100%;
      padding-top: 20px;
*/
      transform: translateX(0);
      transition: transform 0.3s ease-in-out;
      will-change: transform;
      @include media-breakpoint-down(md) {
        height: 100%;
        -webkit-overflow-scrolling: touch;
      }
    }
    .grobal-link.is-jp {
      display: none;
      padding: 10px 13px 30px;
        @include media-breakpoint-down(sm) {
            display: flex;
        }
    }
    .side-link {
      display: none;
      @include media-breakpoint-down(sm) {
        display: block;
      }
      &__item {
        font-size: 14px;
        color: #000;
        a {
            display: block;
            padding: 5px 0;
            color: #000;
        }
      }
      .icon-new {
        margin-right: 10px;
        color: #f00;
      }
    }
    .login {
      padding: 20px 15px 10px;
      border-bottom: 1px solid $color-border-gray;
      @include media-breakpoint-up(md) {
        padding: 20px 20px 10px;
      }
    }
    .btn {
      margin-bottom: 10px;
      padding: 0 30px 0 12px;
      font-size: 16px;
      text-align: left;
      box-shadow: none;
      @include media-breakpoint-up(md) {
        font-size: 14px;
      }
    }
    .btn-profile {
      line-height: 50px;
    }
    .search {
      overflow: hidden;
      padding: 20px 15px;
      border-bottom: 1px solid $color-border-gray;
      .md-form {
        margin-bottom: 10px;
      }
      @include media-breakpoint-up(md) {
        padding: 20px;
      }
      .md-form {
        margin: 0 0 18px;
        .form-control {
          margin: 0;
          padding-right: 35px;
          box-sizing: border-box;
          &:focus:not([readonly]) {
            & + button {
              color: $primary-color;
            }
          }
        }
      }
      &__submit {
        position: absolute;
        top: 50%;
        right: 0;
        padding: 10px;
        transform: translateY(-50%);
        transition: color .15s ease-in-out;
      }
      &__link {
        float: right;
        color: #000;
        .fas {
          padding-left: 10px;
        }
      }
    }
  }
}
