/** - - - - - - - - - - - -
 * header
 - - - - - - - - - - - - */
//header
.ie11 {
  .header.is-teaser {
      .header-menu {
          flex-grow: 1;
      }
      @media (max-width: 1142px) {
        .header-menu {
          padding-left: 0;
          &__item {
            a:not(.btn) {
              margin: 3px;
              padding: 2px;
              font-size: 12px;
            }
          }
        }
      }
      .header__link {
        max-width: 210px;
        @media (max-width: 992px) {
            max-width: inherit;
        }

      }
  }
}
.header-read {
  padding: 15px 20px;
  font-size: 14px;
  border-bottom: 1px solid #e6e6e6;
  max-width: calc(100% - 200px);
  @media (max-width: 768px) {
    padding: 10px 15px;
    font-size: 12px;
    //text-align: center;
  }
  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }
}


//追加EN
.header-read-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  .is-en & {
    position: relative;
    z-index: 11;
    background-color: #fff;
  }
  @include media-breakpoint-down(sm) {
      display: block;
  }
  .header-read {
    border-bottom: none;
  }
  .grobal-link {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.header {
  position: relative;
  z-index: 99;
  display: flex;
  height: 60px;
  background-color: #fff;
  border-bottom: 1px solid $color-border-gray;
  &.is-teaser {
    border-bottom: none;

  }
  &.is_kitchencar,
  &.is-domestic {
    .header-menu {
      .mypage {
        .btn-mypage {
          width: 132px;
          text-align: left;
          span {
            color: #fff;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    height: 90px;
  }
  &__logo {
    display: flex;
    align-items: center;
    margin: 0;
    .site-logo {
      display: block;
      max-width: 107px;
      margin: 10px;
      color: #000;
      font-size: 16px;
      line-height: 1.2;
      text-align: center;
      img {
        width: 100%;
      }
      @include media-breakpoint-up(lg) {
        max-width: 144px;
        margin: 20px;
        .is-en & {
          max-width: 123px;
        }
        img {
          width: 144px;
        }
      }
      @include media-breakpoint-down(sm) {
        margin: 10px 15px;
      }
    }
  }
  &__link {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    a {
      margin: 10px;
      @include media-breakpoint-up(lg) {
        margin: 20px;
      }

      @include media-breakpoint-down(sm) {
        margin: 10px 15px;
      }

      &:hover {
        opacity: 0.5;
      }
      img {
        width: 100%;
        max-width: 135px;
        vertical-align: middle;
        height: auto;

        @include media-breakpoint-up(lg) {
          max-width: 210px;
        }
      }
    }
  }
  .article-top-link {
    display: flex;
    align-items: center;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    a {
      color: #000;
    }
  }
}
.header-menu-trigger {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 10px;
  transform: translate(-50%, -50%);
  .fas {
    color: $primary-color;
    font-size: 20px;
  }
}

.header-menu {
  top: 60px !important;
  left: 0;
  .is-production & {
    display: none;
    @include media-breakpoint-up(md) {
      display: flex;
    }
  }
  display: none;
  float: none;
  width: 100%;
  min-width: inherit;
  align-items: center;
  margin: 0;
  padding-left: 20px;
  list-style: none;;
  border: none;
  transform: translate(0) !important;
  &.show {
    box-shadow: 0 4px 5px 1px rgba(0,0,0,0.1);
  }
  @include media-breakpoint-up(lg) {
    position: static !important;
    width: auto;
    &.show {
      display: flex;
      box-shadow: none;
    }
  }
  &__item {
    position: relative;
    @media(max-width: 1300px) {
      &.hide-link {
        display: none;
      }
    }
    .icon-new {
      position: absolute;
      top: -8px;
      left: 5px;
      font-size: 12px;
      font-weight: bold;
      color: #f00;
    }
    a {
      display: block;
      margin: 10px 0;
      padding: 5px;
      color: #000;
      font-size: 16px;
      white-space: nowrap;
      &:hover {
        color: $primary-color;
      }
      .is-production & {
        @include media-breakpoint-up(md) {
          margin: 5px;
          font-size: 14px;
        }
      }
      @include media-breakpoint-up(lg) {
        margin: 5px;
        font-size: 14px;
      }
      @include media-breakpoint-up(xl) {
        font-size: 16px;
      }
    }
    .btn {
      padding: 7px 17px;
      border-radius: 20px;
      box-shadow: none;
      &:hover {
        box-shadow: $shadow_1;
      }
    }
    .btn-login {
      color: $primary-color;
      border: 2px solid $primary-color;
      &:hover {
        color: #fff;
        background-color: $primary-color;
      }
    }
    .btn-logout {
      border: 2px solid $color-border-gray;
      &:hover {
        color: #fff;
        background-color: #000;
        border-color: #000;
      }
    }
  }
 @include media-breakpoint-up(lg) {
   display: flex;
 }
  .header-menu-service {
    position: relative;
    &:hover {
      .header-menu-service__contents {
        display: block;
      }
    }
    &__contents {
      position: absolute;
      z-index: 10;
      top: 38px;
      left: 50%;
      width: 130px;
      display: none;
      text-align: center;
      background-color: #fff;
      box-shadow: 0 4px 5px 1px rgba(0,0,0,0.1);
      transform: translateX(-50%);
      a {
        display: block;
        margin: 0 !important;
        &:hover {
          color: #000 !important;
          background-color: #ace639;
        }
      }
    }
  }
}

.is-en {
  .header {
    .site-logo {
      @include media-breakpoint-down(sm) {
        svg {
          width: 107px;
        }
      }
    }
    &__link {
      a {
        span {
          vertical-align: middle;
          line-height: 1;
        }
        .material-icons {
          color: #00B842;
        }
        font-size: 16px;
        color: #3B4043;
        display: flex;
        align-items: center;
        &:hover {
          color: #00B842;
        }
      }
    }
    .grobal-list {
      padding-left: 0;
    }
  }
  .header-menu__item .btn {
    padding: 7px 17px;
  }
  .header-read-wrap .header-read {
    max-width: calc(100% - 200px);
    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }

  }
  .category-menu {
    position: relative;
    z-index: 10;
    @include media-breakpoint-down(sm) {
      display: none;
    }
    .category-wrap {
      display: none;
    }
    .category {
      position: absolute;
      z-index: 3;
      top: 50px;
      left: 50%;
      width: 100%;
      transform: translateX(-50%);
    }
    &__btn {
      position: relative;
      z-index: 3;
      .category-menu-btn-sp {
        display: none;
        @include media-breakpoint-down(sm) {
          display: block;
        }
      }
      .category-menu-btn-pc {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
      a {
        max-width: 100%;
        padding: 5px 20px;
        font-size: 12px;
        text-align: left;
        border-radius: 0;
        background-color: #fff;
        svg {
          transition: all 0.3s;
        }
        &:hover {
          color: #fff;
          background-color: #00B842;
          box-shadow: none;
          svg {
            fill: #fff;
          }
        }
      }
      border-bottom: 1px solid $color-border-gray;
    }
    &__overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.7);
      z-index: 2;
      cursor: pointer;
    }
  }
}
//キッチンカーメッセージ用

.is_kitchencar.header-read-wrap .header-read {
    width: calc(100% - 180px);
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
.is_kitchencar {

  .site-logo {
    span {
      display: block;
      text-align: left;
      margin-bottom: 5px;
      font-size: 15px;
      //font-weight: bold; 
    }
    path {
      fill: $kit-color1;
    }
  }
   //ヘッダー
  .header-read {
    font-size: 13px;
    @include media-breakpoint-down(sm) {
      padding: 10px;
    }
  }
}

.is_kitchencar.header {
  .btn-logout {
    &:hover {
    color: #fff !important;
    border-color: #3b4043 !important;
    background-color: #3b4043 !important;
    }
  }
  .btn-login {
    padding: 7px 20px;
    //border: 2px solid $mall-color3;
    border-radius: 20px;
    span {
      //color: $mall-color3;
    }
    &:hover {
      background-color: $mall-hover !important;
      border-color: $mall-hover !important;
      span {
        color: #fff;
      }
    }
  }
  .header-menu {
    @media (max-width: 1250px) {
      width: auto;
    }
  }
  .header-menu__item {
    a {
      position: relative;
      &:hover {
        color: $mall-hover;
      }      
    }
    .btn:hover {
      box-shadow: none !important;
    }
    .icon-new {
      position: absolute;
      top: -8px;
      left: 5px;
      font-size: 12px;
      font-weight: bold;
      color: #f00;
    }
    &.hide-link {
      @media (max-width: 1250px) {
        display: none;
      }
    }
  }
  .site-logo {
    max-width: 157px;
  }
  @include media-breakpoint-down(sm) {
    &__link {
      a {
        margin: 10px;
      }
    }
    .site-logo {
      margin: 10px;
      span {
        font-size: 11px;
      }
    }
    #site-logo {
      width: 108px;
    }
  }
}
