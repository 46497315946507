/** - - - - - - - - - - - -
 * Utils
 - - - - - - - - - - - - */

.clearfix{
  @include clearfix();
}
.bold{
  font-weight: bold
}

/* - - - margin - - - */
@for $classNum from 0 through 20{
  .u-mt#{5 * $classNum} {
   margin-top: 5px * $classNum !important;
  }
  .u-mb#{5 * $classNum} {
   margin-bottom: 5px * $classNum !important;
  }
}

.u-no-margin {
  margin: 0 !important;
}

/* - - - padding - - - */
@for $classNum from 0 through 20{
  .u-pt#{5 * $classNum} {
   padding-top: 5px * $classNum !important;
  }
  .u-pb#{5 * $classNum} {
   padding-bottom: 5px * $classNum !important;
  }
}

.u-no-padding {
  padding: 0 !important;
}

/* - - - font size - - - */
@for $classNum from 0 through 26{
  .u-fs#{6 + $classNum} {
   font-size: 6px + $classNum !important;
  }
}

/* - - - width - - - */
@for $classNum from 0 through 10{
  .u-w#{10 * $classNum} {
   width: 10% * $classNum !important;
  }
}
