@mixin pos-center() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    @content;
}

@mixin contentsPadding($padding:0 10px) {
  padding: $padding;
  @include media-breakpoint-up(md) {
    padding: 0 20px;
  }
}

@mixin breakWord() {
  word-wrap: break-word;
  white-space: normal;
  word-break: break-all;
}

@mixin iconFont($content:"\E002", $color:$key_color_r, $size:12px){
  content: $content;
  font-family: "iconfont" !important;
  speak: none;
  color: $color;
  font-size: $size;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin arrowLink($color:$key_color_r, $size:12px) {
  $link: &;

  font-size: 14px;
  font-weight: bold;


  &:after{
    @include iconFont;

    margin-left: 8px;
  }

  html:not(.sp_tb) & {
    &:hover {
      text-decoration: none;
  
      #{$link}__txt {
        text-decoration: underline;
      }
    }
  }
}

@mixin linkHoverOpacity($display:block, $time:0.5s, $opacity:0.5) {
  display: $display;
  transition: opacity $time;

  &:hover {
    text-decoration: none;
  }
  html:not(.sp_tb) & {
    &:hover {
      opacity: $opacity;
    }
  }
}

@mixin ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// placeholder
@mixin placeholderColor($color) {
  &:placeholder-shown {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color:$color;
  }
  &:-moz-placeholder {
    color:$color;
    opacity: 1;
  }
  &::-moz-placeholder {
    color:$color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color:$color;
  }
}

// loading
@mixin loading($size: 40px, $margin: 40px auto, $color: #fff, $count: 12, $animationDuration: 1.2s) {
  margin: $margin;
  width: $size;
  height: $size;
  position: relative;

  &__elem {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__elem::before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: $color;
    border-radius: 100%;
    animation: loading $animationDuration infinite ease-in-out both;
  }

  @for $i from 2 through $count {
    &__elem:nth-child(#{$i}) { transform: rotate(360deg / $count * ($i - 1)); }
  }

  @for $i from 2 through $count {
    &__elem:nth-child(#{$i})::before { animation-delay: - $animationDuration + $animationDuration / $count * ($i - 1); }
  }
}

@keyframes loading {
  0%, 80%, 100% { opacity: 0 }
  40% { opacity: 1 }
}

// btn
@mixin btn($color:$key_color_r, $letterSpacing:0.05em) {
  position: relative;
  margin: 0 auto;
  color: $color;
  font-weight: bold;
  letter-spacing: $letterSpacing;
  text-align: center;
  border: 2px solid $color;
  cursor: pointer;
}

@mixin iconBtn($color:$primary-color, $icon:"\E002", $size:12px, $letterSpacing:0.05em) {
  position: relative;
  margin: 0 auto;
  color: $color;
  font-weight: bold;
  letter-spacing: $letterSpacing;
  text-align: center;
  border: 2px solid $color;
  cursor: pointer;

  &::after {
      @include iconFont($content:$icon, $color:$color, $size:$size);

      position: absolute;
      z-index: 1;
      top: 50%;
      right: 17px;
      transform: translateY(-50%);
  }
}

// pager
@mixin pager($bgColor:$white) {
  display: flex;
  justify-content: center;
  margin: 80px 0 0;

  a {
    display: block;
    width: 30px;
    height: 30px;
    margin: 0 10px;
    font-size: 14px;
    font-weight: bold;
    line-height: 30px;
    text-align: center;
    background-color: $bgColor;
    border-radius: $default_radius;
    transition: color $default_duration, background $default_duration;

    &:hover {
      text-decoration: none;
    }
    html:not(.sp_tb) & {
      &:hover {
        color: $white;
        background-color: $key_color_r;
  
        &::before {
          color: $white;
        }
      }
    }
  }
  .active a {
    color: $white;
    text-decoration: none;
    background-color: $key_color_r;
    cursor: default;
  }

  &__prev a::before {
    @include iconFont($content:'\E001', $color:$key_color_r, $size:14px);
    vertical-align: middle;
    transition: color $default_duration;
  }

  &__next a::before {
    @include iconFont($content:'\E002', $color:$key_color_r, $size:14px);
    vertical-align: middle;
    transition: color $default_duration;
  }
}

// search
@mixin search() {
  position: relative;

  & form {
    display: flex;
  }

  &__input {
    flex-grow: 1;
    height: 44px;
    padding: 16px 39px 16px 16px;
    font-size: 14px;
    letter-spacing: 0.04em;
    background-color: $base_color;
    border-radius: 0 3px 3px 0;
  }

  &__button {
    position: absolute;
    top: 0;
    right: 0;
    width: 44px;
    height: 44px;
    cursor: pointer;

    &::before {
      @include iconFont($content:'\E00B' ,$color:$key_color_r, $size:22px);
    }
  }
}

//maxWidth

@mixin maxWidth() {
  max-width: 1248px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}
