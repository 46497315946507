/** - - - - - - - - - - - -
 * block
 - - - - - - - - - - - - */
 .section {
  margin-bottom: 100px;

/*
  .ttl-section {
    margin-bottom: 40px;
  }

  .ttl-section--s {
    margin-bottom: 14px;
  }
*/
}

 .card {
   overflow: hidden;
   color: #000;
   .ie11 & {
     box-shadow: 0 -1px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
     &:hover {
       box-shadow: 0 0 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
     }
   }
   &:hover {
      color: #000;
      box-shadow: $z-depth-2; 
     .card__name {
      color: $primary-color;
     }
     .card__img {
       opacity: 0.5;
     }
   }
   &__img {
     width: 100%;
     padding-bottom: 100%;
     transition: all 0.2s ease-in-out;
     background-position: center center;
     background-size: cover;

     img {
       width: 100%;
     }
   }
   &__user {
     position: relative;
     z-index: 2;
     margin-top: -30px;
     text-align: center;
     @include media-breakpoint-up(md) {
      margin-top: -59px;
     }
     &-img {
       overflow: hidden;
       width: 59px;
       height: 59px;
       margin: 0 auto;
       border-radius: 50%;
       border: 2px solid #fff;
       background-size: cover;
       background-position: center center;
       @include media-breakpoint-up(md) {
        width: 110px;
        height: 110px;
       }
     }
     &-name {
       padding: 5px 18px 0;
       color: $color-text-gray2;
       font-size: 10px;
       @include media-breakpoint-up(md) {
         font-size: 16px;
       }
     }
   }
   &__name {
     margin: 0 10px 5px;
     padding: 0 0 5px;
     font-size: 12px;
     line-height: 1.4;
     border-bottom: 1px solid $color-border-gray;
     transition: all 0.2s ease-in-out;
     @include media-breakpoint-up(md) {
        margin: 0 20px 10px;
        padding: 0 0 10px;
        font-size: 20px;
     }
   }
   &__details {
    overflow: hidden;
    margin: 0 10px 5px;
    font-size: 10px;
    border-bottom: 1px solid $color-border-gray;
     @include media-breakpoint-up(md) {
      margin: 0 20px 10px;
      font-size: 14px;
     }
    dt {
      clear: both;
      float: left;
      color: $color-text-gray2;
      font-weight: normal;
    }
    dd {
      margin-left: 40px;
      margin-bottom: 5px;
      @include media-breakpoint-up(md) {
        margin-left: 60px;
      }
    }
  }
  &__explanation {
    flex-grow: 1;
    margin: 0 10px 5px;
    font-size: 10px;
    @include media-breakpoint-up(md) {
      margin: 0 20px 15px;
      font-size: 14px;
    }
  }
  .btn-tag {
    width: auto;
    font-size: 10px;
    @include media-breakpoint-up(md) {
      font-size: 14px;
    }
  }
  .tag-list {
    padding: 0 10px 5px;
    @include media-breakpoint-up(md) {
      padding: 0 20px 15px;
    }
  }
}

//左カラムの上
.login-user {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  font-size: 14px;
  &__txt {
    width: calc(100% - 40px);
    & > span {
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &__name {
    .login-user--supplier & {
      color: $color-supplier;
    }
    .login-user--buyer & {
      color: $color-buyer;
    }
    span {
      color: #000;
    }
  }
  &__img {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid transparent;
    .login-user--supplier & {
      border-color: $color-supplier;
    }
    .login-user--buyer & {
      border-color: $color-buyer;
    }
    div {
      overflow: hidden;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      background-size: cover;
      background-position: center center;
    }
  }
}

//左カラム下段
.side-menu {
  position: relative;
  z-index: 2;
  border-bottom: 1px solid $color-border-gray;
  &__ttl {
    padding: 24px 20px 0;
    color: $color-text-gray;
    font-size: 14px;
  }
  &__item {
    position: relative;
    font-size: 14px;
    &.is-active {
      a {
        background-color: $color-menu-hover;
      }
    }
    a {
      display: block;
      &.is-active {
        background-color: $color-menu-hover;
      }
    }
    .fas {
      position: absolute;
      top: 50%;
      right: 20px;
      font-size: 12px;
      transform: translateY(-50%);
    }
    & > a {
      position: relative;
      display: flex;
      align-content: center;
      padding: 4px 18px;
      color: #000;
    }
    &:hover {
      background-color: $color-menu-hover;
      .side-menu-child {
        display: block;
      }
    }
  }
  &__hassub {
    a {
      position: relative;
      display: flex;
      align-content: center;
      padding: 4px 18px;
      color: #000;
    }
  }
  &__thum {
    overflow: hidden;
    width: 22px;
    img {
      border: 1px solid $color-border-gray;
      border-radius: 50%;
    }
  }
  &__txt {
    width: calc(100% - 22px);
    padding-left: 8px;
  }
  &__link {
    display: block;
    padding: 20px 18px;
    color: #000;
    font-size: 14px;
    &:hover {
      color: $primary-color;
    }
    .fas {
      margin-right: 10px;
    }
  }
  
  .category-serach ,
  .pinpoint-serach {
    position: relative;
//     height: 552px;
    .side-menu__list {
      overflow: auto;
//       height: 552px;
    }
    .side-menu-child {
      display: none !important;
    }
    .ps__rail-y > .ps__thumb-y {
      width: 6px !important;
      background-color: $primary-color !important;
    }
    .ps__rail-y {
      opacity: 1 !important;
      background-color: transparent !important;
    }
  }

  &.is-no-login-search {
    z-index: 10;
    &:hover {
      .balloon-profile {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .balloon-profile {
    position: absolute;
    z-index: 10;
    top: -110px;
    left: 10px;
    visibility: hidden;
    width: 220px;
    padding-bottom: 24px;
    text-align: left;
    border-radius: 4px;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    .txt-b {
      font-size: 12px;
      line-height: 1.2;
      margin-bottom: 6px;
      padding: 10px 0;
      border-radius: 4px;
      text-align: center;
      background-color: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    }
    .btn-login--s {
      display: block;
      line-height: 50px;
      position: relative;
      width: 100%;
      height: 50px;
      padding-left: 0;
      text-align: center;
      color: $primary-color;
      font-size: 14px;
      border: none;
      box-shadow: 0 0 10px rgba(0, 0, 0, .2);
      border-radius: 4px;
      background-color: #fff;
      color: $primary-color;
      font-size: 14px;
      span:not(.fas) {
        display: block;
        background-color: #fff;
        &::before,
        &::after {
          position: absolute;
          z-index: 2;
          right: auto;
          bottom: -15px;
          left: 50%;
          content: '';
          transition: border-color .5s;
          transform: translateX(-50%);
          border-width: 16px 9px 0 9px;
          border-style: solid;
          border-color: #fff transparent;
        }
      }
      &:hover {
         & > span {
          color: #fff;
          background-color: $primary-color;
          .fas{
            color: #fff;
          }
          &::before, 
          &::after {
             border-color: $primary-color transparent;
          }
         }
      }
      & > span {
        transition: all .2s ease-in-out !important;
        &::before, 
        &::after {
          transition: border-color .2s ease-in-out !important;
        }
        .fas {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          transition: color .2s ease-in-out !important;
        }
      }
    }
  }
}

.side-menu-child {
  display: none;
  position: absolute;
  top: 0;
  left: calc(100% + 2px);
  width: 240px;
  padding: 8px 0;
  background-color: #fff;
  transform: translateY(-50%);
  box-shadow: 10px 0 16px 1px rgba(0, 0, 0, 0.1);
  .category-serach-child-wrap & ,
  .pinpoint-serach-child-wrap & {
    left: 240px;
    z-index: 3;
  }
  a {
    display: block;
    color: #000;
    font-size: 14px;
    padding: 4px 20px;
    &:hover {
      background-color: $color-menu-hover;
    }
    @include media-breakpoint-up(md) {
      .fas { display: none; }
    }
  }
}
.category-serach-child-wrap,
.pinpoint-serach-child-wrap {
  .category-serach-menu ,
  .pinpoint-serach-menu {
    display: none !important;
  }
  .side-menu-child {
    & > li {
      position: relative;
      &:hover {
        & > a {
          background-color: #ace639;
        }
        .side-menu-sub-child {
          display: block;
        }
      }
    }
  }
  .side-menu-sub-child {
    & > li {
      position: relative;
      &:hover {
        & > a {
          background-color: #ace639;
        }
        .side-menu-sub-sub-child {
          display: block;
        }
      }
    }
  }
}

.side-menu-sub-sub-child,
.side-menu-sub-child {
  display: none;
  position: absolute;
  top: 0;
  left: calc(100% + 2px);
  width: 240px;
  padding: 8px 0;
  background-color: #fff;
  transform: translateY(-48px);
  box-shadow: 10px 0 16px 1px rgba(0, 0, 0, 0.1);
  .category-serach-child-wrap & ,
  .pinpoint-serach-child-wrap & {
    left: 240px;
    z-index: 3;
  }
  a {
    display: block;
    color: #000;
    font-size: 14px;
    padding: 4px 20px;
    
    &:hover {
      background-color: $color-menu-hover;
    }
    @include media-breakpoint-up(md) {
      .fas { display: none; }
    }
  }
}

.category-serach-child-wrap {
  .side-menu-child,
  .side-menu-sub-child,
  .side-menu-sub-sub-child {
    
    .side-menu__ttl {
      overflow: hidden;
      height: 40px;
      padding: 10px 20px;
    }
    @media (max-width: 980px) {
      width: calc((100vw - 240px) / 3);
    }
    a {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .side-menu-sub-child,
  .side-menu-sub-sub-child {
      left: 100%;
  }
}


.category-serach-child-wrap-sp ,
.pinpoint-serach-child-wrap-sp {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform: translateX(205%);
  transition: transform 0.3s ease-in-out;
  will-change: transform;

  @include media-breakpoint-up(md) {
    display: none !important
  }
  &.is-active {
    transform: translateX(0);
  }

  .side-menu-sub-child,
  .side-menu-sub-sub-child {
      top: 0;
      display: block;
      width: 101%;
      height: 100%;
      box-shadow: none;
      transform: none;
      background-color: #fff;
      z-index: 10;
      transition: transform 0.3s ease-in-out;
      will-change: transform;
  }
  
  .side-menu-sub-child.is-active {
    display: block;
    transform: translateX(-100%);
  }
  .side-menu-sub-sub-child.is-active {
    display: block;
    transform: translateX(-100%);
  }
  
  .category-serach-menu ,
  .pinpoint-serach-menu {
    display: block;
    height: 60px;
    padding-left: 15px;
    color: #fff;
    font-size: 14px;
    line-height: 60px;
    background-color: $color-close;
    .fas {
      margin-right: 10px;
    }
    @include media-breakpoint-up(md) {
      display: none !important;
    }
  }

  .side-menu-child {
    overflow: auto;
    overflow-x: hidden;
    position: static;
    width: 100%;
    height: calc(100% - 60px);
    transform: translateY(0);
    box-shadow: none;
    a {
      position: relative;
      padding: 8px 15px;
      .fas {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
      }
    }
  }

  .side-menu-child,
  .side-menu-sub-child,
  .side-menu-sub-sub-child {
    .side-menu__ttl {
      padding: 10px 15px;
    }
  }
}

/* user-menu*/
.user-menu-wrap {
  height: 53px;
}

.user-menu {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  height: 53px;
  margin: 0 -15px;
  border-bottom: 1px solid $color-border-gray;
  @include media-breakpoint-up(md) {
    margin: 0 -20px;
  }

  &__list {
    display: flex;
    margin: 0 auto;
  }

  &__item {
    &.is-pc {
      display: none;
      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }

  &__link {
    display: block;
    padding: 16px 15px;
    color: #000;
    font-size: 14px;
    &.is-unread {
      display: flex;
      align-items: center;
      .icon-info {
        &::after {
          content: none;
        }
      }
    }
    
    .icon-info {
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-left: 8px;
      background-color: $primary-color;
      border-radius: 50%;
    }

    span {
      position: relative;
      display: block;
      white-space: nowrap;

      &::after {
        position: absolute;
        z-index: 1;
        bottom: -6px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $primary-color;
      }
    }
    
    html:not(.sp_tb) & {
      &:hover {
        text-decoration: none;
        opacity: 1;
  
        span:not(.icon-info)::after {
          content: '';
        }
      }
    }

    &.is-current {
      color: $primary-color;
      opacity: 1;

      span::after {
        content: '';
      }
    }
  }
}

