/** - - - - - - - - - - - -
 * list
 - - - - - - - - - - - - */
 //breadcrumb
.breadcrumb {
  margin: 0;
  padding: 15px 0 0;
  font-size: 12px;
  background-color: transparent;
   @include media-breakpoint-up(md) {
    padding: 20px 0 0;
   }
  @include media-breakpoint-down(sm) {
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 32px;
  }
  a {
    color: #000;
    font-size: 12px;
    &:hover {
      color: $primary-color;
    }
  }
  span {
    color: $color-text-gray;
    font-size: 12px;
  }
  .breadcrumb-item {
    @include media-breakpoint-down(sm) {
      white-space: nowrap;
      &.active {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
 /* tag-list */
.tag-list {
  display: flex;
  flex-wrap: wrap;
  &__item {
    margin: 0 6px 6px 0;
  }
}

//card-lis
.card-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 20px;
  &__item {
    width: calc(50% - 20px);
    margin: 10px;
    .card {
      height: 100%;
    }
  }
  @media (min-width: 911px) {
    max-width: 942px;
  }
  @media (min-width: 1234px) {
    max-width: 1256px;
  }
  @include media-breakpoint-up(md) {
    &__item {
      width: calc(50% - 20px);
    }
  }
  @media (min-width: 911px) {
    &__item {
      width: calc(33.3% - 20px);
    }
  }
  @media (min-width: 1234px) {
    &__item {
      width: calc(25% - 20px);
    }
  }
}
.pager {
  display: flex;
  text-align: center;
  justify-content: center;
  li {
    margin: 5px;
  }
  a,
  li > span {
    display: block;
    width: 30px;
    height: 30px;
    color: #000;
    font-size: 16px;
    border-radius: 50%;
    text-align: center;
    line-height: 29px;
    .fas {
      font-size: 14px;
    }
    &:hover:not(span) {
      color: #fff;
      background-color: $primary-color;
    }
  }
  li > span {
    color: $color-text-gray;
  }
  .active {
    a {
      color: #fff;
      background-color: $primary-color;
    }
  }
}

.month-list {
  display: flex;
  flex-wrap: wrap;
  &__item {
    margin: 0 6px 6px 0;
  }
}

//EN
.grobal-link {
  display: flex;
  align-items: center;
}
.grobal-list {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  
  &__item {
    &.is-current {
      a {
        color: #00B842;
      }
    }
    &:first-child {        
      a {
        &::before {
          content: none;
        }
      }
    } 
  }
  a {
    position: relative;
    line-height: 1;
    padding: 0 10px;
    color: #CDD6DD;
    &:hover {
      color: #00B842;
    }
    &::before {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      content: '';
      width: 1px;
      height: 100%;
      background-color: #CDD6DD;
    }
  }
}

